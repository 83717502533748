import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [4],
		"/horoscope": [~5,[2]],
		"/music": [~6,[3]],
		"/music/artist/[artistId]": [~7,[3]],
		"/music/artist/[artistId]/album/[albumId]": [~8,[3]],
		"/music/playlist/[playlistId]": [~9,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';